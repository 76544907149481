<template>
  <div id="content-wrapper" style="background: #ffffff">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2>Double Art Drop</h2>
          <p>
            <span style="font-weight: bold">Hashmasks: Derivatives</span> by
            <a
              href="https://www.instagram.com/florian.schommer/"
              target="_blank"
              rel="noreferrer"
              >Florian Schommer</a
            >, is an elaborate derivative collection mapped to the existing
            Hashmasks NFTs with 16,384 available to claim. Florian is an award
            winning illustrator with his own distinct artistic style whose
            clients include WWF, Sony and The Economist. His work has been
            featured in many publications including Wired, Fast Company, and
            Colossal.
            <br />
            <a
              href="https://opensea.io/collection/hashmasks-derivatives"
              target="_blank"
              rel="noreferrer"
              >View on Opensea</a
            >
          </p>
          <p>
            <span style="font-weight: bold">Hashmasks: Elementals</span> by
            <a
              href="https://www.instagram.com/ahammaday/"
              target="_blank"
              rel="noreferrer"
              >Hamm</a
            >, is a new collection inspired by the Hashmasks NFTs with 16,384
            unique characters. Robots, Puppets, Voyagers, and Travellers explore
            the universe in search for answers to the mysteries of the
            Hashmasks. Hamm is a prominent artist in the NFT space, and has
            illustrated several projects including Nomads, the first ENS
            avatars. NFT's from this collection will be randomly assigned for
            each claimed Hashmask with this option.
            <br />
            <a
              href="https://opensea.io/collection/hashmasks-elementals"
              target="_blank"
              rel="noreferrer"
              >View on Opensea</a
            >
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h2>Claim Options [1 Claim Per Hashmask]</h2>
          <ul>
            <li>
              <span style="font-weight:bold;">Free claim</span> + gas of 1
              <span style="font-weight:bold;">Hashmasks Derivative </span> by
              Florian Schommer
            </li>
            <li>
              <span style="font-weight:bold;">Free claim</span> + gas of 1
              randomly assigned
              <span style="font-weight:bold;">Hashmasks Elemental </span>by Hamm
            </li>
            <li>
              <span style="font-weight:bold;">Burn</span> your Hashmask + gas
              and
              <span style="font-weight:bold;">receive both NFTs </span>
            </li>
          </ul>
          <p>
            You may select up to 10 Hashmasks per claim transaction.
          </p>
        </div>
      </div>
      <br />
      <h2>Verified Smart Contracts</h2>
      <p class="ethereum-address-block">
        <b>Hashmasks Contract: </b
        ><a
          target="_blank"
          rel="noreferrer"
          href="https://etherscan.io/address/0xc2c747e0f7004f9e8817db2ca4997657a7746928"
          >0xc2c747e0f7004f9e8817db2ca4997657a7746928</a
        >
      </p>
      <p class="ethereum-address-block">
        <b>DoubleDrop Contract: </b>
        <a :href="doubleDropEtherscanLink" target="_blank" rel="noreferrer">{{
          doubleDropContractAddress
        }}</a>
      </p>
      <p class="ethereum-address-block">
        <b>Hashmasks Elementals Contract: </b>
        <a :href="elementalsEtherscanLink" target="_blank" rel="noreferrer">{{
          elementalsContractAddress
        }}</a>
      </p>
      <p class="ethereum-address-block">
        <b>Hashmasks Derivatives Contract: </b>
        <a :href="derivativesEtherscanLink" target="_blank" rel="noreferrer">{{
          derivativesContractAddress
        }}</a>
      </p>
      <div v-if="!isClaimActive && !loading.masks">
        <div class="row">
          <div class="col">
            <h2>Claiming Soon</h2>
          </div>
        </div>
      </div>
      <div v-if="!isClaimActive && loading.masks" class="row">
        <div class="col">
          <div class="loading-circle" />
        </div>
      </div>
      <div v-if="!loading.masks && isClaimActive">
        <div>
          <div class="row ">
            <h2 class="col-12">Claim</h2>
            <div class="col-12 col-md-auto">
              <div class="doubledrop-menu">
                <div class="claim-toggles col-12">
                  <h4 class="row">Claim Options</h4>
                  <div class="form-check row ">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="activeSelection"
                      value="Hashmasks"
                      id="hashmasks"
                    />
                    <label class="form-check-label" for="hashmasks">
                      <h6>
                        View Hashmasks
                      </h6>
                    </label>
                  </div>
                  <div class="form-check row">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="activeSelection"
                      id="derivatives"
                      value="Derivatives"
                    />
                    <label class="form-check-label" for="derivatives"
                      ><h6>View Derivatives</h6></label
                    >
                  </div>
                  <div class="form-check row">
                    <input
                      class="form-check-input"
                      type="radio"
                      v-model="activeSelection"
                      id="elementals"
                      value="Elementals"
                    />
                    <label class="form-check-label" for="elementals"
                      ><h6>View Elementals</h6></label
                    >
                  </div>
                </div>
                <div class="col-12 claim-buttons">
                  <div class="row">
                    <button
                      class="claim-button"
                      v-on:click="redeemDerivatives"
                      :disabled="
                        numberSelected === 0 || isRedeeming !== undefined
                      "
                    >
                      <div v-if="isRedeeming === 'Derivatives'">
                        <div class="loading-circle" />
                      </div>
                      <span v-else>Claim Derivative(s)</span>
                    </button>
                  </div>
                  <div class="row">
                    <button
                      class="claim-button"
                      v-on:click="redeemElementals"
                      :disabled="
                        numberSelected === 0 || isRedeeming !== undefined
                      "
                    >
                      <div v-if="isRedeeming === 'Elementals'">
                        <div class="loading-circle" />
                      </div>
                      <span v-else>Claim Elemental(s)</span>
                    </button>
                  </div>
                  <div class="row">
                    <button
                      class="claim-button"
                      v-on:click="showBurnModal"
                      :disabled="
                        numberSelected === 0 || isRedeeming !== undefined
                      "
                    >
                      Burn for Both
                    </button>
                  </div>
                  <div class="row" style="width: 150px">
                    <p style="line-height: 20px;">
                      You may select up to 10 Hashmasks per transaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-sm">
              <div class="doubledrop-menu">
                <div class="col-12">
                  <div class="row">
                    <div class="col col-md-12 col-lg-6">
                      <h4 class="row">Double Drop Claim Status</h4>
                      <h6>
                        Total Derivatives Claimed: {{ derivativesRedeemed }} of
                        16384
                      </h6>
                      <h6>
                        Total Elementals Claimed: {{ elementalsRedeemed }} of
                        16384
                      </h6>
                      <p>
                        Double Drop NFTs can only be claimed once per Hashmask.
                      </p>
                      <p>
                        Claim Period: Indefinite
                      </p>
                      <p>
                        Input a Hashmask ID to check redemption status.
                      </p>
                      <div class="row">
                        <div
                          class="col-auto lined thick"
                          style="margin-left: 12px;"
                        >
                          <input
                            type="number"
                            placeholder="ID"
                            min="0"
                            max="16383"
                            v-debounce="search"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-auto"
                      v-if="searchItem"
                      style="text-align: center; margin: 1rem;"
                    >
                      <h3 style="text-align: center; margin: 0.25rem;">
                        #{{ searchItem.tokenId }}
                      </h3>
                      <div
                        class="row"
                        style="margin: 0.75rem; text-align: center"
                      >
                        <img
                          class="claim-check-image"
                          v-bind:src="searchItem.image"
                        />
                        <div class="col">
                          <div class="row" style="margin-bottom: 0.25em">
                            <img
                              class="col claim-check-image"
                              v-bind:src="searchItem.elemental"
                              v-if="
                                searchItem.redemption === 'Elemental' ||
                                  searchItem.redemption === 'Burned'
                              "
                            />
                          </div>
                          <div class="row">
                            <img
                              class="col claim-check-image"
                              v-bind:src="searchItem.derivative"
                              v-if="
                                searchItem.redemption === 'Derivative' ||
                                  searchItem.redemption === 'Burned'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div style="text-align: center;">
                        <p style="margin: 0">
                          {{
                            searchItem.isRedeemed ? 'Redeemed' : 'Not Redeemed'
                          }}
                        </p>
                        <p>
                          {{ searchItem.redemption }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!items.length && !loading.masks" class="row">
          <div class="alert alert-warning col" role="alert">
            You don't own any Hashmasks. Go get one!
          </div>
        </div>
        <div class="row">
          <div class="col" style="margin-left: 0.5rem;">
            <h4 v-if="numberSelected === 0">None Selected</h4>
            <h4 v-if="numberSelected > 0">
              {{ numberSelected }} Hashmask(s) Selected
            </h4>
          </div>
        </div>
        <div class="portfolio-overlay dark text-overlay centered row">
          <div
            class="portfolio-item col-6 col-sm-4 col-md-3 col-lg-2"
            v-for="item in filteredItems()"
            :key="item.tokenId"
          >
            <a
              v-on:click="select(item)"
              style="cursor: pointer;"
              :disabled="isRedeeming !== undefined"
            >
              <div class="portfolio-content">
                <div
                  v-if="isSelected(item.tokenId)"
                  class="selected-image-border"
                >
                  <div
                    style="opacity: 1;"
                    class="portfolio-text-content redemption-info"
                  >
                    <div class="portfolio-text" style="opacity: 1;">
                      <h3>Selected</h3>
                    </div>
                  </div>
                </div>
                <div
                  :class="{
                    'square-image': activeSelection !== 'Hashmasks',
                    'redeemed-image': item.isRedeemed,
                  }"
                  class="portfolio-img-content"
                >
                  <img
                    v-if="activeSelection === 'Hashmasks'"
                    v-bind:src="item.image"
                  />
                  <img
                    v-if="activeSelection === 'Derivatives'"
                    v-bind:src="item.derivative"
                  />
                  <img
                    v-if="activeSelection === 'Elementals'"
                    v-bind:src="item.elemental"
                  />
                </div>
              </div>
              <div
                v-if="!isSelected(item.tokenId)"
                class="portfolio-text-content redemption-info"
              >
                <div class="portfolio-text">
                  <h3>#{{ item.tokenId }}</h3>
                  <h3 v-if="activeSelection === 'Hashmasks'">
                    {{ item.name || 'Unknown (yet)' }}
                  </h3>
                  <h5
                    style="color: burlywood;"
                    v-if="item.redemption !== undefined"
                  >
                    {{ item.redemption }}
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <modal name="burnConfirmationForm" height="auto" :scrollable="true">
      <div class="container-fluid" style="margin: 1em;">
        <div class="approval-container">
          <h2>Burn Masks</h2>
          <p>
            You understand that if you decide to
            <span style="font-weight: bold; color: crimson">
              burn
            </span>
            your Hashmask NFT that
            <span style="font-weight: bold; color: crimson"
              >you no longer own that Hashmask NFT.</span
            >
          </p>
          <div v-if="user.isApprovedForAll === false && !shouldAllowBurn()">
            <p>
              To burn your masks, you must give our Double Drop contract
              transfer approval.
            </p>
          </div>
          <div class="row">
            <div
              v-for="item in Object.values(this.selections)"
              :key="item.tokenId"
              class="col"
            >
              <div style="min-width: 142px">
                <img height="200px" v-bind:src="item.image" />
              </div>
              <p style="margin:0">{{ item.tokenId }}</p>
              <button
                v-if="
                  !user.isApprovedForAll && !selectionsApprovals[item.tokenId]
                "
                class="approve-button"
                v-on:click="approve(item.tokenId)"
              >
                <div
                  v-if="isApprovalLoading[item.tokenId]"
                  class="loading-circle"
                />
                <span v-if="!isApprovalLoading[item.tokenId]">Approve</span>
              </button>
              <p
                class="uppercase"
                style="font-weight: bold; color: green;"
                v-else
              >
                Approved
              </p>
            </div>
          </div>
          <button
            class="claim-button"
            v-on:click="burnMasksToRedeemBoth"
            v-if="shouldAllowBurn()"
            style="font-size: 16px;text-align: center;"
            :disabled="isRedeeming !== undefined"
          >
            <div v-if="isRedeeming === 'Both'">
              <div class="loading-circle" />
            </div>
            <span v-else>Burn Masks</span>
          </button>
        </div>
        <div>
          <p style="margin-bottom:0;">
            Only allow transfer approvals for our Double Drop contract
          </p>
          <p>
            <a
              style="font-weight: bold; font-size: 12px; color: burlywood;"
              :href="doubleDropEtherscanLink"
              target="_blank"
              rel="noreferrer"
            >
              {{ doubleDropContractAddressShort }}
            </a>
          </p>
          <p>
            Confirm you're connected to
            <span style="color: black; font-weight: bold;"
              >thehashmasks.com</span
            >
          </p>
          <div class="row">
            <div class="col"></div>
            <div class="col-auto">
              <button
                class="cancel-button col-auto"
                v-on:click="hideBurnModal"
                v-if="!isRedeeming"
                style="font-size: 16px;text-align: center; color: black; borde"
                :disabled="isRedeeming !== undefined"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  isClaimActive,
  tokenOfOwnerByIndex,
  isApprovedForAll,
  isApprovedForTransfer,
  hashmasksBalanceOf,
  elementalsBalanceOf,
  derivativesBalanceOf,
  getNFTName,
  getEthAddress,
  performTransferApproval,
  burnMasksForDoubleRedemption,
  redeemDerivatives,
  redeemElementals,
  waitForTransaction,
  redemptionType,
  derivativesSupply,
  elementalsSupply,
} from '../helpers/contract.js';
import {
  DOUBLEDROP_CONTRACT_ADDRESS,
  ELEMENTALS_CONTRACT_ADDRESS,
  DERIVATIVES_CONTRACT_ADDRESS,
  ELEMENTALS_PREVIEW_URL,
} from '@/assets/constants';
import { getRedemptionSignature } from '../helpers/redemption.js';
import router from '../router/index.js';
import { STARTING_INDEX } from '../assets/constants';
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import vueModal from 'vue-js-modal';

Vue.use(vueDebounce);
Vue.use(vueModal);

const Selection = {
  Hashmasks: 'Hashmasks',
  Elementals: 'Elementals',
  Derivatives: 'Derivatives',
};

export default {
  name: 'DoubleDrop',
  components: {},
  data() {
    return {
      router,
      user: {
        balance: { elementals: 0, derivatives: 0 },
        isApprovedForAll: false,
      },
      loading: { masks: true },
      inputs: { checkMaskNumber: '' },
      selections: {},
      activeSelection: Selection.Hashmasks,
      numberSelected: 0,
      searchItem: undefined,
      elementalsRedeemed: 0,
      derivativesRedeemed: 0,
      selectionsApprovals: {},
      isApprovalLoading: {},
      isRedeeming: undefined,
      isClaimActive: false,
    };
  },
  computed: {
    doubleDropContractAddressShort: function() {
      return (
        DOUBLEDROP_CONTRACT_ADDRESS.slice(0, 6) +
        '...' +
        DOUBLEDROP_CONTRACT_ADDRESS.slice(
          DOUBLEDROP_CONTRACT_ADDRESS.length - 4,
        )
      );
    },
    doubleDropEtherscanLink: function() {
      return 'https://etherscan.io/address/' + DOUBLEDROP_CONTRACT_ADDRESS;
    },
    elementalsEtherscanLink: function() {
      return 'https://etherscan.io/address/' + ELEMENTALS_CONTRACT_ADDRESS;
    },
    derivativesEtherscanLink: function() {
      return 'https://etherscan.io/address/' + DERIVATIVES_CONTRACT_ADDRESS;
    },
    doubleDropContractAddress: function() {
      return DOUBLEDROP_CONTRACT_ADDRESS;
    },
    elementalsContractAddress: function() {
      return ELEMENTALS_CONTRACT_ADDRESS;
    },
    derivativesContractAddress: function() {
      return DERIVATIVES_CONTRACT_ADDRESS;
    },
  },
  async mounted() {
    await this.updateUserState();
    await this.updateNFTState();
    this.isClaimActive = await isClaimActive();
  },
  watch: {
    searchText: function() {
      this.search();
    },
  },
  methods: {
    filteredItems: function() {
      switch (this.activeSelection) {
        case 'Derivatives':
          return this.items.filter(
            (item) => !item.isRedeemed || item.redemption === 'Derivative',
          );
        case 'Elementals':
          return this.items.filter(
            (item) => !item.isRedeemed || item.redemption === 'Elemental',
          );
        case 'Hashmasks':
        default:
          return this.items;
      }
    },
    shouldAllowBurn: function() {
      return Object.values(this.selectionsApprovals).reduce((a, b) => {
        return a && b;
      }, true);
    },
    search: async function(id) {
      if (id.length === 0) {
        this.searchItem = undefined;
        return;
      }
      const tokenId = parseInt(id);
      if (tokenId < 0 || tokenId > 16383) {
        this.searchItem = undefined;
        return;
      }

      const name = await getNFTName(tokenId);
      const redemption = await redemptionType(tokenId);
      const isRedeemed = redemption !== undefined;
      const image =
        'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/' +
        this.revealedMaskIndex(tokenId) +
        '.png';
      const derivative =
        'https://hm-deriv-previews.s3.us-east-1.amazonaws.com/' +
        this.revealedMaskIndex(tokenId) +
        '.preview.png';
      const elemental = isRedeemed
        ? `${ELEMENTALS_PREVIEW_URL}/${tokenId}`
        : '/images/redemption-placeholder.jpg';
      this.searchItem = {
        name,
        isRedeemed,
        tokenId,
        image,
        redemption,
        derivative,
        elemental,
      };
    },
    select: function(item) {
      if (item.isRedeemed) {
        return;
      }
      const tokenId = item.tokenId;

      // Hate having to count this manually, but vue refuses to recompute
      const currentSelection = this.selections[tokenId];
      if (!currentSelection) {
        if (this.numberSelected === 10) return;
        this.selections[tokenId] = item;
        this.numberSelected++;
        if (!this.user.isApprovedForAll) {
          Vue.nextTick(async () => {
            if (!this.selectionsApprovals[tokenId]) {
              await this.checkApproval(tokenId);
              this.$forceUpdate();
            }
          });
        }
      } else {
        delete this.selections[tokenId];
        this.numberSelected--;
      }
      this.$forceUpdate();
    },
    checkApproval: async function(tokenId) {
      this.isApprovalLoading[tokenId] = true;

      const approved = await isApprovedForTransfer(tokenId);
      this.selectionsApprovals[tokenId] = approved;
      this.isApprovalLoading[tokenId] = false;
    },
    isSelected: function(tokenId) {
      return this.selections[tokenId];
    },
    revealedMaskIndex: function(nftIndex) {
      return (Number(nftIndex) + STARTING_INDEX) % 16384;
    },
    approve: async function(tokenId) {
      this.isApprovalLoading[tokenId] = true;
      this.$forceUpdate();

      const tx = await performTransferApproval(tokenId);
      await waitForTransaction(tx.hash);
      const approved = await isApprovedForTransfer(tokenId);
      this.selectionsApprovals[tokenId] = approved;
      delete this.isApprovalLoading[tokenId];
      this.$forceUpdate();
    },
    updateUserState: async function() {
      this.user.balance.elementals = await elementalsBalanceOf();
      this.user.balance.derivatives = await derivativesBalanceOf();
      this.user.isApprovedAll = await isApprovedForAll();
    },
    updateNFTState: async function() {
      this.selections = {};
      this.numberSelected = 0;
      this.loading.masks = true;
      var items = [];
      const balance = await hashmasksBalanceOf();

      this.elementalsRedeemed = await elementalsSupply();
      this.derivativesRedeemed = await derivativesSupply();
      for (let i = 0; i < balance; i++) {
        const tokenId = await tokenOfOwnerByIndex(i);
        const name = await getNFTName(tokenId);
        const redemption = await redemptionType(tokenId);
        const isRedeemed = redemption !== undefined;
        const image =
          'https://hashmasksstore.blob.core.windows.net/hashmasksthumbs/' +
          this.revealedMaskIndex(tokenId) +
          '.png';
        const derivative =
          'https://hm-deriv-previews.s3.us-east-1.amazonaws.com/' +
          this.revealedMaskIndex(tokenId) +
          '.preview.png';
        const elemental = isRedeemed
          ? `${ELEMENTALS_PREVIEW_URL}/${tokenId}`
          : '/images/redemption-placeholder.jpg';
        items.push({
          name,
          isRedeemed,
          tokenId,
          image,
          derivative,
          elemental,
          redemption,
        });
      }

      this.items = items.sort((a, b) => {
        if (a.isRedeemed && !b.isRedeemed) {
          return 1;
        } else if (!a.isRedeemed && b.isRedeemed) {
          return -1;
        } else {
          return a.tokenId - b.tokenId;
        }
      });

      this.loading.masks = false;
      this.$forceUpdate();
    },
    redeemElementals: async function(event) {
      this.isRedeeming = 'Elementals';
      try {
        const tokenIds = Object.keys(this.selections).map((id) => parseInt(id));
        const ethAddress = await getEthAddress();
        const signature = await getRedemptionSignature(tokenIds, ethAddress);
        const tx = await redeemElementals(signature, tokenIds);
        this.$store.commit('addPendingTx', {
          txHash: tx.hash,
          description: `Redeeming Elementals`,
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        await this.updateNFTState();
      } catch (error) {
        console.error(error);
      }
      this.isRedeeming = undefined;
      event.preventDefault();
    },
    redeemDerivatives: async function(event) {
      this.isRedeeming = 'Derivatives';
      try {
        const tokenIds = Object.keys(this.selections).map((id) => parseInt(id));
        const ethAddress = await getEthAddress();
        const signature = await getRedemptionSignature(tokenIds, ethAddress);
        const tx = await redeemDerivatives(signature, tokenIds);
        this.$store.commit('addPendingTx', {
          txHash: tx.hash,
          description: `Redeeming Derivatives`,
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        await this.updateNFTState();
      } catch (error) {
        console.log(error);
      }
      this.isRedeeming = undefined;
      event.preventDefault();
    },
    showBurnModal: async function() {
      this.$modal.show('burnConfirmationForm');
    },
    hideBurnModal: async function() {
      this.$modal.hide('burnConfirmationForm');
    },
    burnMasksToRedeemBoth: async function(event) {
      this.isRedeeming = 'Both';
      try {
        const tokenIds = Object.keys(this.selections).map((id) => parseInt(id));
        const ethAddress = await getEthAddress();
        const signature = await getRedemptionSignature(tokenIds, ethAddress);
        const tx = await burnMasksForDoubleRedemption(signature, tokenIds);
        this.$store.commit('addPendingTx', {
          txHash: tx.hash,
          description: `Burning masks for double redemption`,
        });
        const receipt = await waitForTransaction(tx.hash);
        console.log(receipt);
        this.hideBurnModal();
        await this.updateNFTState();
      } catch (error) {
        console.error(error);
      }
      this.isRedeeming = undefined;
      event.preventDefault();
    },
  },
};
</script>
