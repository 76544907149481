import axios from 'axios';
import { SIGNING_URL } from '../assets/constants';

const getRedemptionSignature = async (tokenIds, walletAddress) => {
  try {
    const sorted = tokenIds.map((token) => parseInt(token)).sort();
    const response = await axios.post(SIGNING_URL, {
      token_ids: sorted,
      address: walletAddress,
    });
    const signature = response.data.signature;
    return signature;
  } catch (error) {
    console.error('Could not retrieve token signature', error);
    return undefined;
  }
};

export { getRedemptionSignature };
